<template>
  <!-- <div class="prices-container"> -->
  <div class="prices-container">
    <h1 class="has-text-primary has-text-weight-bold" :class="displayOnTouch ? 'is-size-huge-mobile' : 'is-size-huge'">Tarifs</h1>
    <h2 class="has-text-primary is-size-3 has-text-weight-bold is-uppercase">Des méthodes traditionnelles de stockage qui ne répondent plus à vos besoins.</h2>
    <p class="has-text-justified	">
      <em>Le marché du stockage de bateaux est de plus en plus contraignant, saturé, et ce, partout en France.</em>
    </p>
    <p>
      <strong class="is-uppercase">Des solutions, pour la plupart, hors de prix …</strong>
      Chaque année, vous en faites l’amer constat : stocker votre bateau vous revient de plus en plus cher. Les places au port deviennent des denrées rares, et on le sait, tout ce qui est rare est bien souvent onéreux.
    </p>
    <p>
      <strong class="is-uppercase">…. et peu pratiques !</strong>
      <br/>
      Alors on se dirige vers les ports à sec. Une alternative qui présente un autre souci majeur : une accessibilité réduite.
      Imaginez la scène ; c’est le week-end, les beaux jours arrivent, et vous souhaitez réaliser 2 ou 3 bricolages sur votre bateau avant la mise à l’eau.  Et bien cela est tout bonnement impossible. Placé sur des cales en hauteur, votre bateau est inatteignable, plus question d’y toucher durant toute la durée du stockage… Dommage... Pourtant, tout le monde devrait pouvoir stocker son bateau sans difficulté et au meilleur prix !
    </p>
    <h2 class="has-text-primary is-size-3 has-text-weight-bold is-uppercase"><span class="break">JeStockeMonBateau.fr :</span> la garantie de prix justes !</h2>
    <p>
      <strong class="is-uppercase">  Je suis bateau-stockeur ; je mets en location gratuitement mon espace et au tarif que je souhaite !</strong><br>
      La tarification est entièrement de votre ressort, vous avez une liberté totale dans l’estimation de la location.
    </p>
    <p>
      Cependant, si vous avez besoin d’un coup de pouce, nous sommes là pour vous aider à définir le tarif optimal, celui qui vous assurera une location régulière et sur le long terme ! Le prix recommandé apparaitra une fois votre annonce entièrement complétée.
    </p>
    <p>
      En effet, quelques critères font varier sensiblement le montant :
    </p>
    <p>
      <div v-for="(list, index) in lists" :key="'list-'+index">
        <h3 class="has-text-weight-bold">{{list.title}}</h3>
        <p>
          {{list.content}}
        </p>
      </div>
      <p>Publier mon annonce sur JeStockeMonBateau.fr est 100% gratuit, entièrement sécurisé, et je suis libre de louer au prix que je désire !</p>
    <h2 class="has-text-primary is-size-3 has-text-weight-bold is-uppercase">Comment et quand suis-je payé ?</h2>
    <p>
      Pour percevoir mes paiements, je renseigne mon RIB lorsque je complète mon profil. Je suis ensuite crédité 24h après l’entrée du bateau-skipper dans les lieux, puis chaque mois à cette même date (si j’ai souscris pour une mensualité), ou chaque semaine ou jour (selon
      le mode de location préféré). Vous recevez votre loyer en toute sérénité, les transactions sont gérées par JeStockeMonBateau.fr via un système de paiement ultra sécurisé.
    </p>
    <router-link tag="a" :to="{name:'Wizard'}" class="button is-primary">C’est parti, je publie mon annonce</router-link>
    <br>
    <br>
    <p>
      <strong class="is-uppercase">Je suis bateau-skipper : je loue à un prix juste un espace qui me correspond !</strong>
    </p>
    <p>
      Plusieurs bateaux-stockeurs me sont proposés en fonction des critères que j’ai indiqués. Je rentre en contact avec eux et choisis l’offre qui me semble la meilleure.
      C’est facile, j’obtiens tous les prix en quelques clics, et nous vous garantissons 100% de transparence !
      Un contretemps de dernière minute ? Une offre plus avantageuse proposée par un autre bateau-stockeur ? Pas de panique, vous pouvez annuler gratuitement jusqu’à 24h avant l’entrée des lieux (hors frais de commission).
    </p>
    <p>
     <router-link tag="a" :to="{name:'Research'}" class="button is-primary">Je consulte les offres</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Prices',
  data () {
    return {
      lists: [
        {
          title: '1. L’espace',
          content: 'Le nombre de m² disponibles influe sur le prix.'
        },
        {
          title: '2. La sécurité',
          content: 'Mon espace est clôturé, je possède une caméra de télésurveillance, une alarme … etc. Un environnement sûr et sécurisé représente indéniablement une valeur ajoutée.'
        },
        {
          title: '3. La localisation',
          content: 'La situation géographique a une répercussion sur le tarif. La distance entre le lieu de stockage et celui de la mise à l’eau ou du port est primordiale. Plus celle-ci est courte, plus votre espace est attractif !'
        },
        {
          title: '4. Type d’espace',
          content: 'Intérieur – extérieur. Vous possédez un garage ou un entrepôt dans lequel le bateau sera protégé des aléas du temps ? C’est un plus.'
        },
        {
          title: '5. L’accès du bateau-skipper',
          content: 'Peut-il accéder à son bateau sur rendez-vous uniquement ou bien quand il le souhaite puisqu’il est détenteur des clefs ? Une accessibilité pleine et entière augmentera votre tarification.'
        }
      ]
    }
  },
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Prices' });

    let title = (siteName+' - Tarifs - Stockage de bateaux chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
      description = siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! C\'est vous qui définissez les tarifs en fonction des besoins que vous soyez bateau-stocker ou bateau-skipper, nous nous occupons du reste !';

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'property': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'property': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
        {
          'property': 'twitter:image',
          'content': (baseUrl+require('./../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .prices-container {
    margin: 5% 30%;
  }
  @media screen and (max-width: 2048px){
    .prices-container {
      margin: 5% 25%;
    }
  }
  @media screen and (max-width: 1640px){
    .prices-container {
      margin: 5% 20%;
    }
  }
  @media screen and (max-width: 1365px){
    .prices-container {
      margin: 5% 15%;
    }
  }
  @media screen and (max-width: 1171px){
    .prices-container {
      margin: 5% 10%;
    }
  }
  .prices-container h1{
    padding-bottom: 5%;
  }
  .is-size-huge {
    font-size: 10rem;
    hyphens: auto;
  }
  .is-size-huge-mobile{
    font-size: 6rem;
  }
  h2 {
    line-height: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .break {
    word-break: break-all
  }
  p {
    margin-bottom: 1rem;
    text-align: justify;
  }
  @media screen and (max-width: 1087px) {
    .container {
      margin: 5%;
    }
  }
  @media screen and (max-width: 537px) {
    .container {
      margin: 5%;
    }
    .is-size-huge-mobile{
      font-size: 3rem;
      line-height: 3rem;
    }
  }
</style>
